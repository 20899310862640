<template>
  <div>
    <h3 ref="Introduction">
      Definition of a Simple Pendulum
    </h3>
    <p>
      A simple pendulum consists of a point mass (called a bob) suspended from a frictionless pivot using a massless string or rod. As the suspended mass is displaced from its equilibrium position, it experiences a restoring force due to the action of gravity &mdash; as a result of which the mass oscillates back and forth around its equilibrium position at a constant amplitude. Note that, realistically speaking, there is always friction acting at the pivot and air resistance opposing the motion of the mass, and the string or the rod is not exactly massless. Thus, a simple pendulum represents a mathematical idealization.
    </p>
    <h3 ref="Period">
      Time Period of Oscillation
    </h3>
    <p>
      The time taken in one complete cycle (starting from one extreme to another extreme and back) is called time period of oscillation. The time period of oscillation for a simple pendulum depends on the length of the rod (\(L\)) and the acceleration due to gravity (\(g\)) &mdash; given the amplitude of the oscillation is small.
      $$T = 2\pi \sqrt{\frac{L}{g}}$$
    </p>
    <h3 ref="EquationofMotion">
      Equation of Motion for a Simple Pendulum
    </h3>
    <p>
      Consider a simple pendulum consisting of a bob of mass \(m\) suspended from a pivot O using a massless rod/string of length \(L\). When the bob is given an angular displacement (say \(\theta\) ) from the vertical position, the weight of the bob generates a restoring moment about the pivot. This restoring moment tends to bring the bob back to the vertical position. The restoring moment about the pivot due to weight of the mass is given as:
      $$\tau_o = -mgL\sin \theta \tag{1}$$
      Now, recall that Newton's second law for rotational motion is given as:
      $$ \tau_o = I_o \ddot{\theta} \tag{2}$$
      where \(I_o\) is the moment of inertia of the bob about the pivot, and is given as:
      $$I_o = m L^2$$
      By combining equations (1) and (2), we obtain:
      $$ mL^2 \ddot{\theta} + mgL \sin \theta =0$$
      After a slight rearrangement, we obtain the equation of motion for the pendulum as:
      $$ \ddot{\theta} + \frac{g}{L} \sin \theta =0$$
    </p>
    <h3 ref="smallAngle">
      Small-Angle Approximation and Simple Harmonic Motion
    </h3>
    <p>
      If the amplitude of angular displacement is small enough for the small-angle approximation (\(\sin \theta \approx \theta\)) to hold, then the equation of motion reduces to the equation of simple harmonic motion.
      $$ \ddot{\theta} + \frac{g}{L}\theta =0$$
      The solution of the above equation is given as:
      $$\theta = \theta_0 \cos(\omega t)$$
      where \(\omega = \sqrt{g/L}\) is the frequency of oscillation, and is related to time period of oscillation as:
      $$\omega = \frac{2 \pi}{T}$$
      This gives the time period of oscillation as
      $$T = 2 \pi \sqrt{\frac{L}{g}}$$
    </p>
    <h3 ref="play">
      MagicGraph: Oscillations of a Simple Pendulum
    </h3>
    <p>
      Through this MagicGraph, you will learn about the time period of the oscillations of a simple pendulum.
      The pendulum consists of a bob of mass \(m\) that is suspended from a pivot O by means of a massless rod of length \(L\). The acceleration due to gravity is \(g\).
    </p>
    <h5> To Get Started: </h5>
    <ul style="list-style-type:square;">
      <li> Choose a value for the length of the rod using the slider on the left. </li>
      <li> Choose a value for the gravity using the slider on the right.</li>
      <li> Drag the bob away from the vertical position. Then, let it go and observe the oscillations of the pendulum.</li>
    </ul>
    <br>
    <h5> To Explore: </h5>
    <ul style="list-style-type:square;">
      <li> How the oscillations of the pendulum change as you change the length? </li>
      <li> How the oscillations of the pendulum change as you change the gravity? </li>
    </ul>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'SimplePendulum',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Simple Pendulum';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Introduction', img:'/assets/number-1.svg', action: () => this.goto('Introduction')},
      {title: 'Period of Oscillation', img:'/assets/number-2.svg', action: () => this.goto('EquationofMotion')},
      {title: 'Equation of Motion', img:'/assets/number-3.svg', action: () => this.goto('EquationofMotion')},
      {title: 'Small Angle Approximation', img:'/assets/number-4.svg', action: () => this.goto('smallAngle')},
      {title: 'PlayGraph', img:'/assets/touch.svg', action: () => this.goto('play')},
      ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
    },
   mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    }
  }
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
