import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeShuffle,
    placeTest
} from '../../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
    var board = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox: [-7, 1.8, 7, -12.2], keepaspectratio: true, axis:false, grid:true, ticks:false,
      showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
	  JXG.Options.board.minimizeReflow = 'none';
    board.options.layer['image']=20;
  makeResponsive(board);
  placeLogo(board);
  //Length of the pendulum
	board.create('text', [-2.95, 1, '<b>Oscillations of a Pendulum</b>'], {fixed:true, fontSize:function(){return 32*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	board.create('text', [-3.95, -11.65, 'Length (m)'], {fixed:true, fontSize:function(){return 24*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	board.create('text', [2.5, -11.65, 'Gravity (m/s<sup>2</sup>)'], {fixed:true, fontSize:function(){return 24*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	board.create('text', [-5., -9, 'Time Period (sec) ='], {fixed:true, fontSize:function(){return 24*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    var cc =   board.create('slider',[[-6, -11.1],[-1,-11.1],[4,4.5,8]],{baseline:{strokeWidth:7, strokeColor:'grey'},
    highline:{strokeWidth:7, strokeColor:'black'}, name:'',size:function(){return 8*board.canvasHeight/800},face:'square', fillColor:'grey',strokeColor:'black',
    withTicks:false,label:{offset:[2,-15], fontSize:function(){return 20*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  //
    var sup = board.create('polygon', [[-8, 0.0], [8, 0.0], [8, 5], [-8, 5]], {fillColor:'grey', strokeColor:'grey', withLines:false, vertices:{visible:false}, fixed:true});
    var p1 = board.create('point', [0.0, 0.0], {visible:true, name: 'O', size: 4, fillColor:'black', strokeColor:'black' , fixed:true, label:{fontSize:function(){return 18*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var pb = board.create('point', [0.0, -5.0], {visible:false, name: '', size: 4, fillColor:'black', strokeColor:'black', fixed:true });
    var vert = board.create('line', [p1, pb], {visible:true, strokeColor:'grey', strokeWidth:1, dash:2, fixed:true});
    var p1p = board.create('point', [0.0, 0.0], {visible:true, name: '', size: 3, fillColor:'grey', strokeColor:'black', fixed:true});
    var c1 = board.create('circle', [[0,0], [0,function(){return cc.Value();}]], {visible:false, name: 'circle', size: 3, fixed:true});
    var p2 = board.create('glider', [3,-4, c1], {visible: true, name: 'Drag me!', size:function(){return 18*board.canvasHeight/800}, fillColor:'orange', strokeColor:'black', shadow:true, label:{fontSize:function(){return 18*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
    var p3 = board.create('point', [0, -5], {name:'', size:0, fixed:true});
    var ang =board.create('minorsector', [p1, p2, p3],{radius:2, fixed:true});
    //var grr=board.create('functiongraph', [function(t){ return -8-2*Math.cos(t/2); },-10, 10],{strokeColor: "#cccccc", strokeWidth:3});
   // var p3 = board.create('point', [
    //            function(){return 2*Math.atan2(p2.X(),-p2.Y())},
    //            function(){return -8-2*Math.cos(Math.atan2(p2.X(),-p2.Y()))}],
    //            {visible: true, name:'', size: 3});
    var linee = board.create('line', [p1, p2], {visible:true, strokeColor:'grey', strokeWidth:6, straightFirst: false,straightLast: false});
    var isInDragMode = false;
	board.create('segment', [[0.51,-11.1],[0.51, -10.80]],{strokeColor:'black'});
	board.create('segment', [[2.25,-11.1],[2.25, -10.80]],{strokeColor:'black'});
	board.create('segment', [[5.47,-11.1],[5.47, -10.80]],{strokeColor:'black'});

	board.create('image', ['/assets/moonn.svg', [0.1, -10.75],[.7,.7]],{fixed:true});
	//board.create('image', ['/assets/mars.svg', [1.05, -11.75],[.7,.7]],{fixed:true});
    board.create('image', ['/assets/earth.svg', [1.9, -10.75],[.7,.7]],{fixed:true});
	board.create('image', ['/assets/jupiter.svg', [5.1, -10.75],[.7,.7]],{fixed:true});
    var gg = board.create('slider',[[0.5,-11.1],[5.5,-11.1],[1.62,9.8,24.8]],{baseline:{strokeWidth:7, strokeColor:'grey'},
    highline:{strokeWidth:7, strokeColor:'black'}, name:'',size:function(){return 8*board.canvasHeight/800},face:'square', fillColor:'grey',strokeColor:'black',
    withTicks:false,label:{offset:[2,-15], fontSize:function(){return 20*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

	board.create('text', [0.1, -9.5, 'Moon'], {fixed:true, fontSize:function(){return 18*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', rotate:45, visible:function(){if(Math.abs(gg.Value()-1.7) <=0.25){return true} else{return false}}});

	board.create('text', [1.9, -9.5, 'Earth'], {fixed:true, fontSize:function(){return 18*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', visible:function(){if(Math.abs(gg.Value()-9.8) <=0.25){return true} else{return false}}});

	var kk = board.create('text', [5.1, -9.5, 'Jupiter'], {fixed:true, fontSize:function(){return 18*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', visible:function(){if(Math.abs(gg.Value()-24.7) <=0.25){return true} else{return false}}});

	board.create('text', [-2., -9, function(){return (2*Math.PI*Math.sqrt(cc.Value()/gg.Value())).toFixed(2)}], {fixed:true, fontSize:function(){return 24*board.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    function startAnimation(startTheta) {
      var c = 0.0, g = gg.Value(), l = cc.Value();
        p2.moveAlong(function() {
        var f = function(t, x) {
                return [x[1], -c * x[1] - g / l * (Math.sin(x[0]))];};
        var area = [0, 200];
        var numberOfEvaluations = (area[1] - area[0]) * 100;
        var data = JXG.Math.Numerics.rungeKutta('heun', [startTheta, 0], area, numberOfEvaluations, f);
        var duration = 50000;
        return function(t) {
            if (t >= duration)
                return NaN;
            var angle2=-Math.PI/2+data[Math.floor(t / duration * numberOfEvaluations)][0];
            return [p1.X()+l*Math.cos(angle2),p1.Y()+l*Math.sin(angle2)];};
        }());
      }
    function hook() {
      if(!isInDragMode) {
        if(board.mode === board.BOARD_MODE_DRAG) {
            board.stopAllAnimation();
            isInDragMode = true;
        }
      }
    if(isInDragMode) {
        if(board.mode !== board.BOARD_MODE_DRAG) {
            isInDragMode = false;
           // var angle=Math.atan2(p2.Y()-p1.Y(),p2.X()-p1.X())+Math.PI*1/2;
            startAnimation(Math.PI/5.);
                                                  }
                    }
  }
    board.on('move', function(){hook()});
    startAnimation(0.0);
  }
}
export default Boxes;
